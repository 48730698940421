@import "../../styles/style";
@import "../../styles/ebase_editor-theme.css";

.diffModalParent {
  width: 90% !important;
  max-width: unset !important;
  border-radius: 8px;
}

.ebase-editor-pink-highlight {
  background-color: #ff99ff;
}