// App Variables

$xodus_midnight: #161C31;
$xodus_blue: #4564AD;
$xodus_cobalt: #2581C4;
$xodus_sky: #3DA3DC;
$xodus_turquoise: #28b8CE;
$xodus_orange: #F3943E;
$xodus_yellow: #FBBC42;
$xodus_lime: #C6D54F;
$xodus_green: #93C255;
$xodus_teal: #3FB498;
$xodus_coral: #EC694A;
$xodus_red: #E95053;
$xodus_magenta: #EA5178;
$xodus_fuchsia: #CA4F97;
$xodus_purple: #804996;
$xodus_body_grey: #63676C;
$xodus_caption_grey: #95999E;
$xodus_accent_grey: #DBDFE4;
$xodus_base_grey: #ECEFF4;

$app-primary: $xodus_turquoise;
$app-primary-hover: #1fa3b8;
$app-nav-bar-bg: $xodus_midnight;
$app-font-color: #676a6c;

$app-btn-primary-disabled: $xodus_turquoise;

// bootstrap overrides

//$list-group-action-active-bg: $app-primary;
$primary: $app-primary;

$table-color: $app-font-color;

// old ebase colours:
$old-primary: #1ab394;
$old-primary-hover: #18a68a;

div#app{
  background-color: #191d30;
  height: 100%;
}

.form-select{
  color: $app-font-color;
  font-size: 14px;
  border-radius: 1px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.label-primary{
  background-color: $old-primary !important;
}
.debug {
  border: solid 1px red;
}

// Custom app global
.eb-div-ctr {
  display: flex;
  justify-content: center;
  align-items: center;
}
.eb-div-l {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.eb-div-r {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.eb-absolute-button {
  position: absolute;
  top: 12px;
  right: 15px;
  margin: 0px;
}

.highlight-changed {
  background-color: #FFED54;
}
.border-changed {
  border: 8px #FFED54 solid;
}
.highlight-deleted {
  background-color: #F6BEB5;
  text-decoration: line-through;
}
.border-deleted {
  border: 8px #F6BEB5 solid;
}
.diff-view-table-border td, .diff-view-table-border th {
  border: solid 1px black;
  padding: 2px;
}

.linked-hover:hover {
  cursor: pointer;
}

.ibox-content {
  border-top: 0 !important;
}
